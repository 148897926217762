<app-exam-layout [flex]="true" [includeQuestionSideBar]="false">
    <app-header text="Troubleshooting" [showConnectionIndicator]="false" [enableTimer]="false" [showProgressBar]="false"
        header></app-header>
    <mat-card appearance="outlined" class="w-100" bottom-content>
        <mat-card-content>
            <div class="row mb-2">
                <div class="col">
                    <button mat-mini-fab class="tbp me-2" routerLink="/">
                        <mat-icon>home</mat-icon>
                    </button>
                    <button mat-raised-button class="tbs me-2" (click)="accordion.openAll()">Expand All</button>
                    <button mat-raised-button class="tbs" (click)="accordion.closeAll()">Collapse All</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-accordion multi>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <b>Logging into exam</b>
                                </mat-panel-title>
                                <mat-panel-description>
                                    Are you having difficulty logging into your exam?
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            Things to try:
                            <ul>
                                <li>Talk to your invigilator and check that your PIN is correct.</li>
                                <li>Try refreshing the page.</li>
                                <li>Navigate to the login page by clicking <a href="javascript:void(0)"
                                        routerLink="/login">here</a>.</li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <b>Exam in progress</b>
                                </mat-panel-title>
                                <mat-panel-description>
                                    Are you having problems taking your exam?
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            Things to try:
                            <ul>
                                <li>If you experienced an error in the middle of your exam, try navigating back to the
                                    exam page by clicking <a href="javascript:void(0)" routerLink="/exam">here</a>.</li>
                                <li>If you have opened your exam in multiple tabs, try closing all but one Highfield
                                    Core tab. You should only have one Highfield Core tab open.</li>
                                <li>
                                    If you have encountered memory errors (Chrome example e.g. "Aw, Snap! Google Chrome
                                    ran out of memory while trying to display this webpage.").
                                    <ul>
                                        <li>Chrome/Edge/Firefox: Press <i>Ctrl+Shift+Del</i> to bring up the clear data
                                            modal and press ok and navigate back to your <a href="javascript:void(0)"
                                                routerLink="/exam">exam</a>.</li>
                                    </ul>
                                </li>
                                <li>
                                    If you are having trouble loading exam assets, the causes could possibly be:
                                    <ul>
                                        <li>Are you taking your exam at work? Your place of business may have a firewall
                                            or other method blocking the download of our assets.</li>
                                        <li>Are you using a VPN? While not always the case, this could block the
                                            download of our assets.</li>
                                    </ul>
                                </li>
                                <li>
                                    Options to try could be the following:
                                    <ul>
                                        <li>Changing the device you are taking the exam on.</li>
                                        <li>Changing the internet connection you are using to take the exam.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <b>Uploading results</b>
                                </mat-panel-title>
                                <mat-panel-description>
                                    Are you trying to upload exam results after going offline during an exam?
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            Things to try:
                            <ul>
                                <li>Talk to your invigilator and check that your PIN is correct.</li>
                                <li>Ensure that you are connected to the Internet.</li>
                                <li>Navigate to the upload page <a href="javascript:void(0)"
                                        routerLink="/upload">here</a>.</li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title> <b>Connection Issues</b> </mat-panel-title>
                                <mat-panel-description> Is our connection test telling you that it can't connect?
                                </mat-panel-description>
                            </mat-expansion-panel-header> Things to try:
                            <ul>
                                <li> Are you taking your exam at your place of work?
                                    <ul>
                                        <li>There may be an issue with your work internet connection or firewall. Please
                                            contact your network administrator at work if this is the case.</li>
                                    </ul>
                                </li>
                                <li> Are you taking your exam at home?
                                    <ul>
                                        <li>There may be an issue with your router settings or firewall. </li>
                                    </ul>
                                </li>
                                <li>Ensure that you are connected to the Internet.</li>
                                <li>Try a tethered mobile connection (4G).</li>
                                <li>Try a different Wi-Fi connection or internet cable.</li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <b>Connection issues</b>
                                </mat-panel-title>
                                <mat-panel-description>
                                    Is our connection test telling you that it can't connect?
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            Things to try:
                            <ul>
                                <li>
                                    Are you taking your exam at your place of work?
                                    <ul>
                                        <li>There may be an issue with your work internet connection or firewall. Please contact your network administrator at work if this is the case.</li>
                                    </ul>
                                </li>
                                <li>
                                    Are you taking your exam at home?
                                    <ul>
                                        <li>There may be an issue with your router settings or firewall. </li>
                                    </ul>
                                </li>
                                <li>Ensure that you are connected to the Internet.</li>
                                <li>Try a tethered mobile connection (4G).</li>
                                <li>Try a different Wi-Fi connection or internet cable.</li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <app-footer [enableFlagBtn]="false" [enableErrorBtn]="false" [enablePreviousButton]="false"
        [enableNextButton]="false" footer></app-footer>
</app-exam-layout>